import Header from "views/Header";
import React, { useEffect, useState } from "react";
import {
  useApprovalStatus,
  useFamousNft,
  useGetRewardTokenBalance,
  useNFT,
  useNFTApprovalStatus,
  useNFTUserData,
  usePLSPerBlock,
  useRewardTokenBalance,
  useStakedNFT,
} from "state/user/hooks";
import { FamousData, NFTData } from "state/user";
import picOne from "../../assets/static/imgs/1pic-details.png";
import picTwo from "../../assets/static/imgs/2pic-details.png";
import picThree from "../../assets/static/imgs/3pic-details.png";
import picFour from "../../assets/static/imgs/4pic-details.png";

import pic1 from "../../assets/1pic.png";
import pic2 from "../../assets/2pic.png";
import pic3 from "../../assets/3pic.png";
import pic4 from "../../assets/4pic.png";
import coins from "../../assets/coins.png";
import down from "../../assets/down-arrow.png";
import logo from "../../assets/logo.png";
import stakeImg from "../../assets/stake.png";
import unStakeImg from "../../assets/unstake.png";
import nft from "../../assets/icon.png";
import { useWeb3Context } from "hooks/useWeb3Context";
import toast from "react-hot-toast";
import { callApproveForAll, callClaim, callStakeNFTs, callUnstakeNFTs } from "utils/calls";
import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import { ethers } from "ethers";
import { SUBGRAPH_URL } from "config";
import Footer from "components/Footer";
import { getStakingAddress } from 'utils/addressHelpers'

const nftsQuery = `
    query MyQuery($owner: Bytes!) {
        nfts(where: {owner: $owner}) {
            tokenId
            stake
        }
    }
`;

const Staking = ({ reloadNftData, setReloadNftData }: any) => {
  const [reload, setReload] = useState(false);
  const web3Context = useWeb3Context();
  useNFTUserData(web3Context?.account, reload);
  useApprovalStatus(web3Context?.account, reload);
  useGetRewardTokenBalance(getStakingAddress(), reload);
  const famous: FamousData = useFamousNft();
  const nftsOnWallet: NFTData[] = useNFT("koba");
  const nftsOnStaking: NFTData[] = useStakedNFT("koba");
  // const [nftsOnWallet, setNFTsonWallet] = useState<NFTData[]>([])
  // const [nftsOnStaking, setNFTsonStaking] = useState<NFTData[]>([])
  const plsPerBlock = usePLSPerBlock("koba");
  const approveState: boolean = useNFTApprovalStatus("koba");
  let pendingRewardTotal = 0;
  nftsOnStaking.map((item: any) => {
    pendingRewardTotal += item.pendingReward;
  });

  const anonBalance: number = useRewardTokenBalance();

  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [selectedNFTsonWallet, setSelectedNFTsonWallet] = useState<number[]>([]);
  const [selectedNFTsonStaking, setSelectedNFTsonStaking] = useState<number[]>([]);

  const [approvePendingTx, setApprovePendingTx] = useState(false);
  const [stakePendingTx, setStakePendingTx] = useState(false);
  const [unStakePendingTx, setUnstakePendingTx] = useState(false);
  const [claimPendingTx, setClaimPendingTx] = useState(false);
  const [unStakeAllPendingTx, setUnstakeAllPendingTx] = useState(false);

  useEffect(() => {
    // const fetchNFTs = async (account: string) => {
    //     try {
    //         // setLoadingReferralLint("loading")
    //         const client = new ApolloClient({
    //             uri: SUBGRAPH_URL,
    //             cache: new InMemoryCache(),
    //         });
    //         const { data: result, error: error } = await client.query({
    //             query: gql(nftsQuery),
    //             variables: {
    //                 owner: account
    //             },
    //             fetchPolicy: "cache-first",
    //         });
    //         console.log("sniper: result1: ", result)
    //         if (result && !error) {
    //             const _nfts = result.nfts.map((item: any) => {
    //                 return {
    //                     id: Number(item.tokenId),
    //                     stake: item.stake,
    //                     name: "",
    //                     image: "https://gateway.pinata.cloud/ipfs/Qma5hBEPrv6tVmpE9knnpu5iSS4eXhsZANhA3Zyjsdf7A4"
    //                 }
    //             }
    //             )
    //             console.log("sniper: _nfts: ", _nfts)
    //             if (_nfts && _nfts.length > 0) {
    //                 setNFTsonWallet(_nfts.filter((item: any) => item.stake === false))
    //                 setNFTsonStaking(_nfts.filter((item: any) => item.stake === true))
    //             } else {
    //                 setNFTsonWallet([])
    //                 setNFTsonStaking([])
    //             }
    //         }
    //         // setLoadingReferralLint("loaded")
    //     } catch (error) {
    //         console.error(error)
    //         // setLoadingReferralLint("failed")
    //     }
    // }
    // if (web3Context?.account) {
    //     fetchNFTs(web3Context.account)
    // }
  }, [web3Context?.account]);

  const selectNFTsonWallet = async (id: number) => {
    let selectedItems = selectedNFTsonWallet.slice();
    selectedItems.push(Number(id));
    setSelectedNFTsonWallet(selectedItems);
  };
  const unSelectNFTsonWallet = async (id: number) => {
    let selectedItems = selectedNFTsonWallet.slice();
    const index = selectedItems.indexOf(Number(id));
    selectedItems.splice(index, 1);
    setSelectedNFTsonWallet(selectedItems);
  };

  const selectNFTsonStaking = async (id: number) => {
    let selectedItems = selectedNFTsonStaking.slice();
    selectedItems.push(Number(id));
    setSelectedNFTsonStaking(selectedItems);
  };
  const unSelectNFTsonStaking = async (id: number) => {
    let selectedItems = selectedNFTsonStaking.slice();
    const index = selectedItems.indexOf(id);
    selectedItems.splice(index, 1);
    setSelectedNFTsonStaking(selectedItems);
  };

  const handleUnStake = async () => {
    if (!web3Context?.account) {
      // setShowModal1(false)
      toast.error("Confirm Your Wallet Connection.");
      return;
    }
    if (selectedNFTsonStaking.length <= 0) {
      // setShowModal2(false)
      toast.error("Select the NFTs that you are going to unstake.");
      return;
    }
    setUnstakePendingTx(true);
    const result = await callUnstakeNFTs(web3Context?.provider, web3Context?.account, "koba", selectedNFTsonStaking);
    setUnstakePendingTx(false);
    if (result) {
      setReload(!reload);
      // setReloadNftData(!reloadNftData)
      setSelectedNFTsonStaking([]);
      setShowModal2(false);
      toast.success("Unstake Success!");
    } else {
      toast.error("Something Went Wrong! Please confirm you are in Ethereum Mainnet.");
    }
  };

  const handleStake = async () => {
    if (!web3Context?.account) {
      // setShowModal1(false)
      toast.error("Confirm Your Wallet Connection.");
      return;
    }
    if (selectedNFTsonWallet.length <= 0) {
      // setShowModal1(false)
      toast.error("Select the NFTs that you are going to stake.");
      return;
    }
    setStakePendingTx(true);
    const result = await callStakeNFTs(web3Context?.provider, web3Context?.account, "koba", selectedNFTsonWallet);
    setStakePendingTx(false);
    if (result) {
      setReload(!reload);
      // setReloadNftData(!reloadNftData)
      setSelectedNFTsonWallet([]);
      setShowModal1(false);
      toast.success("Unstake Success!");
    } else {
      toast.error("Something Went Wrong! Please confirm you are in Ethereum Mainnet.");
    }
  };

  // const handleStakeAll = async () => {
  //     const ids = await nftsOnWallet.map((item: any) => item.id)
  //     setPendingTx(true)
  //     await callStakeNFTs(web3Context?.provider, web3Context?.account, "koba", ids)
  //     setPendingTx(false)
  //     setReload(!reload)
  //     setSelectedNFTsonWallet([])
  // }

  const handleUnStakeAll = async () => {
    if (!web3Context?.account) {
      // setShowModal1(false)
      toast.error("Confirm Your Wallet Connection.");
      return;
    }
    const ids = await nftsOnStaking.map((item: any) => item.id);
    setUnstakeAllPendingTx(true);
    await callUnstakeNFTs(web3Context?.provider, web3Context?.account, "koba", ids);
    setUnstakeAllPendingTx(false);
    setReload(!reload);
    // setReloadNftData(!reloadNftData)
    setSelectedNFTsonStaking([]);
  };

  const handleClaim = async () => {
    if (!web3Context?.account) {
      // setShowModal1(false)
      toast.error("Confirm Your Wallet Connection.");
      return;
    }

    if (selectedNFTsonStaking.length <= 0) {
      // setShowModal2(false)
      toast.error("Select the NFTs that you are going to unstake.");
      return;
    }

    setClaimPendingTx(true);
    await callClaim(web3Context?.provider, web3Context?.account, "koba", selectedNFTsonStaking);
    setClaimPendingTx(false);
    setReload(!reload);
  };

  const handleApprove = async () => {
    if (!web3Context?.account) {
      // setShowModal1(false)
      toast.error("Confirm Your Wallet Connection.");
      return;
    }
    setApprovePendingTx(true);
    const result = await callApproveForAll(web3Context?.provider, "koba", web3Context?.account, true, famous);
    setApprovePendingTx(false);

    if (result) {
      setReload(!reload);
      toast.success("Approve Success! Now you can stake.");
    } else {
      toast.error("Something Went Wrong! Please confirm you are in Ethereum Mainnet.");
    }
  };

  // return (
  //   // <div className="stakeMainPage">
  //   //     <div className="csMAin">
  //   //         <Header />
  //   //         <div className="vidCont">
  //   //             <video className="videoBg" autoPlay loop muted playsInline>
  //   //                 <source src="images/bg.mp4" type="video/mp4" />
  //   //                 Sorry, your browser doesn't support videos.
  //   //             </video>
  //   //             <img className="videoBg" src='images/bg.png' alt='bg' />
  //   //         </div>
  //   //         <div className="csPage">
  //   //             <div className="px-app px-glitch">
  //   //                 <span className="px-glitchtext px-glitchtext-anim">Coming Soon, PulsePixel Cartel</span>
  //   //             </div>

  //   //             <div className="loadMain">
  //   //                 <div className="loader">
  //   //                     <span></span>
  //   //                     <span></span>
  //   //                     <span></span>
  //   //                     <span></span>
  //   //                     <span></span>
  //   //                     <span></span>
  //   //                     <span></span>
  //   //                 </div>
  //   //             </div>
  //   //         </div>
  //   //     </div>
  //   // </div>

  //   <div className="stakeMain">
  //     {showModal1 && (
  //       <div className="formMain1">
  //         <div className="formMain">
  //           <div style={{ position: "relative", height: "100%", paddingTop: "50px", paddingBottom: "20px" }}>
  //             <div className="px-glitchtext1" style={{ marginTop: 0 }}>
  //               My NFTs
  //             </div>
  //             <div style={{ height: "72%", overflowY: "auto", marginBottom: "1rem" }}>
  //               <div className="tokenIds" style={{ marginBottom: 0 }}>
  //                 {nftsOnWallet.map((item: any, index: number) => {
  //                   const selectState =
  //                     selectedNFTsonWallet.filter((item1: any) => Number(item1) === Number(item.id)).length > 0;
  //                   return (
  //                     <div className="" key={index}>
  //                       <div className="flip-card" style={{ marginTop: 0, marginBottom: 0 }}>
  //                         <div className="flip-card-inner">
  //                           <div className="flip-card-front" style={{ position: "static" }}>
  //                             <div className={`${selectState ? "selectedrectangle" : "rectangle"}`}>
  //                               <div className="flip-card-id">PPC#{item.id}</div>
  //                               {selectState ? (
  //                                 <div
  //                                   className="unStakeImg"
  //                                   onClick={() => {
  //                                     unSelectNFTsonWallet(item.id);
  //                                   }}
  //                                 >
  //                                   <img
  //                                     className="images_show"
  //                                     src={item.image}
  //                                     alt="nft"
  //                                     style={{ transform: "scale(1.02)" }}
  //                                   />
  //                                 </div>
  //                               ) : (
  //                                 <div
  //                                   className="unStakeImg"
  //                                   onClick={() => {
  //                                     selectNFTsonWallet(item.id);
  //                                   }}
  //                                 >
  //                                   <img className="images_show" src={item.image} alt="nft" />
  //                                 </div>
  //                               )}
  //                             </div>
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   );
  //                 })}
  //               </div>
  //             </div>
  //           </div>
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center",
  //               position: "fixed",
  //               bottom: "20px",
  //               width: "100%",
  //               flexDirection: "column",
  //               alignItems: "center",
  //             }}
  //           >
  //             {approveState ? (
  //               <button disabled={stakePendingTx} className="button-92" onClick={handleStake}>
  //                 {stakePendingTx ? "Process..." : "Stake"}
  //               </button>
  //             ) : (
  //               <button disabled={approvePendingTx} className="button-92" onClick={handleApprove}>
  //                 {approvePendingTx ? "Process..." : "Approve"}
  //               </button>
  //             )}

  //             {stakePendingTx && (
  //               <div className="loadingContainer">
  //                 <div>
  //                   <div className="loadingText">Staking Your PPC</div>
  //                 </div>
  //               </div>
  //             )}
  //             {approvePendingTx && (
  //               <div className="loadingContainer">
  //                 <div>
  //                   <div className="loadingText">Approving Your PPC</div>
  //                 </div>
  //               </div>
  //             )}
  //           </div>

  //           <button className="closeNote" onClick={() => setShowModal1(false)}>
  //             ✖
  //           </button>
  //         </div>
  //       </div>
  //     )}

  //     {showModal2 && (
  //       <div className="formMain1">
  //         <div className="formMain">
  //           <div style={{ position: "relative", height: "100%", paddingTop: "50px", paddingBottom: "20px" }}>
  //             <div className="px-glitchtext1" style={{ marginTop: 0 }}>
  //               My Staked NFTs
  //             </div>
  //             <div style={{ height: "72%", overflowY: "auto", marginBottom: "1rem" }}>
  //               <div className="tokenIds" style={{ marginBottom: 0 }}>
  //                 {nftsOnStaking.map((item: any, index: number) => {
  //                   const selectState =
  //                     selectedNFTsonStaking.filter((item1: any) => Number(item1) === Number(item.id)).length > 0;
  //                   return (
  //                     <div className="flip-card" style={{ marginTop: 0, marginBottom: 0 }}>
  //                       <div className="flip-card-inner">
  //                         <div className="flip-card-front" style={{ position: "static" }}>
  //                           <div className={`${selectState ? "selectedrectangle" : "rectangle"}`}>
  //                             <div className="flip-card-id">PPC#{item.id}</div>
  //                             {selectState ? (
  //                               <div
  //                                 className="unStakeImg"
  //                                 onClick={() => {
  //                                   unSelectNFTsonStaking(item.id);
  //                                 }}
  //                               >
  //                                 <img
  //                                   className="images_show"
  //                                   src={item.image}
  //                                   alt="nft"
  //                                   style={{ transform: "scale(1.02)" }}
  //                                 />
  //                               </div>
  //                             ) : (
  //                               <div
  //                                 className="unStakeImg"
  //                                 onClick={() => {
  //                                   selectNFTsonStaking(item.id);
  //                                 }}
  //                               >
  //                                 <img className="images_show" src={item.image} alt="nft" />
  //                               </div>
  //                             )}
  //                             <div className="flip-card-reward">{item.pendingReward.toFixed(0)} PLS</div>
  //                             <div className="flip-card-pending">{!item.isClaimed ? "pending" : "claimed"}</div>
  //                           </div>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   );
  //                 })}
  //               </div>
  //             </div>
  //             <div
  //               style={{
  //                 display: "grid",
  //                 justifyContent: "center",
  //                 position: "absolute",
  //                 bottom: "20px",
  //                 width: "100%",
  //                 gridAutoFlow: "column",
  //                 alignItems: "center",
  //               }}
  //             >
  //               <button disabled={claimPendingTx} className="button-92" onClick={handleClaim}>
  //                 {claimPendingTx ? "Process..." : "Claim"}
  //               </button>
  //               <button disabled={unStakePendingTx} className="button-92" onClick={handleUnStake}>
  //                 {unStakePendingTx ? "Process..." : "UnStake"}
  //               </button>
  //             </div>
  //             {claimPendingTx && (
  //               <div className="loadingContainer">
  //                 <div>
  //                   <div className="loadingText">Claiming Your Pending Rewards</div>
  //                 </div>
  //               </div>
  //             )}
  //             {unStakePendingTx && (
  //               <div className="loadingContainer">
  //                 <div>
  //                   <div className="loadingText">Unstaking Your PPC</div>
  //                 </div>
  //               </div>
  //             )}
  //             <button className="closeNote" onClick={() => setShowModal2(false)}>
  //               ✖
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     )}

  //     <Header />
  //     <div className="vidCont">
  //       <img className="videoBg" src="images/bg.png" alt="bg" />
  //     </div>

  //     <div className="markBarMain">
  //       <div className="markBar">
  //         <div className="markBarCon">
  //           <img src={pic1} alt="pic1" />
  //           <div className="markBar2">
  //             <div className="num">{nftsOnStaking.length}</div>
  //             <div className="name">STAKED</div>
  //           </div>
  //         </div>

  //         <div className="markBarCon">
  //           <img src={pic2} alt="pic2" />
  //           <div className="markBar2">
  //             <div className="num">{(plsPerBlock * 8640).toFixed(2)} PLS/Day</div>
  //             {/* 8640 Blocks per day */}
  //             <div className="name">REWARDS PER NFT</div>
  //           </div>
  //         </div>

  //         <div className="markBarCon">
  //           <img src={pic3} alt="pic3" />
  //           <div className="markBar2">
  //             <div className="num">{anonBalance} PLS</div>
  //             <div className="name">BALANCE</div>
  //           </div>
  //         </div>

  //         <div className="markBarCon">
  //           <img src={pic4} alt="pic4" />
  //           <div className="markBar2">
  //             <div className="num">{pendingRewardTotal.toFixed(2)} PLS</div>
  //             <div className="name">PENDING</div>
  //           </div>
  //         </div>
  //         {/* <div className="btnsMainBig">
  //                         <button className="btns" disabled={claimPendingTx} onClick={handleClaim}>{claimPendingTx? "Process..." : "CLAIM"}</button>
  //                         <button className="btns2" disabled={unStakeAllPendingTx} onClick={handleUnStakeAll}>{unStakeAllPendingTx? "Process..." : "UNSTAKE ALL"}</button>
  //                     </div> */}
  //       </div>
  //     </div>

  //     <div className="boardMain">
  //       <div className="mintDiv">
  //         <div className="boardPics">
  //           <div className="stakeT">
  //             <div className="stripe-btn">
  //               <span>STAKE</span>
  //               <div className="corners">
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //               </div>
  //               <div className="stripes">
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //               </div>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="boardPics">
  //           <div className="image2Size">
  //             <img className="rabbitImgLogo" src={stakeImg} alt="nft" />
  //             <div className="num2">PPC NFTs : {nftsOnWallet.length}</div>
  //             <div className="name2">Available</div>
  //           </div>
  //         </div>

  //         <div className="walletConnect">
  //           <button className="wallet3" type="submit" onClick={() => setShowModal1(true)}>
  //             PPC NFTs to Stake
  //             <img className="lookDown" src={down} alt="down" />
  //           </button>
  //         </div>
  //       </div>

  //       <div className="mintDiv">
  //         <div className="boardPics">
  //           <div className="stakeT">
  //             <div className="stripe-btn">
  //               <span>UNSTAKE</span>
  //               <div className="corners">
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //               </div>
  //               <div className="stripes">
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //                 <i></i>
  //               </div>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="boardPics">
  //           <div>
  //             <img className="rabbitImgLogo" src={unStakeImg} alt="rabbit" />
  //             <div className="num2">PPC NFTs : {nftsOnStaking.length}</div>
  //             <div className="name2">Staked</div>
  //           </div>
  //         </div>

  //         <div className="walletConnect">
  //           <button className="wallet3" type="submit" onClick={() => setShowModal2(true)}>
  //             PPC NFTs to Unstake
  //             <img className="lookDown" src={down} alt="lookdown" />
  //           </button>
  //         </div>
  //       </div>
  //     </div>

  //     <div className="cMain">
  //       <p className="cLine"></p>
  //       <img src={logo} alt="logo" />
  //       <div style={{ marginTop: "10px" }}>© 2023 PulsePixel Cartel NFTs. All rights reserved.</div>
  //       <p className="cLine"></p>
  //     </div>
  //   </div>
  // );

  // NEW SOURCE
  return (
    <div className="ns-main-sec">
      <Header />
      <div className="ns-new-container-stacking">
        <div className="ns-stack-main-wrap">
          <div className="ns-stak-details-container">
            {/* Detail One  */}
            <div className="ns-detail-stake">
              <div className="ns-detail-flex-stake">
                <div className="ns-details-logo">
                  <img src={picOne} alt="picOne" />
                </div>
                <div className="ns-detail-content">
                  <span>{nftsOnStaking.length}</span>
                  <span>STAKED</span>
                </div>
              </div>
            </div>
            {/* End Detail One  */}

            {/* Detail TWo  */}
            <div className="ns-detail-stake">
              <div className="ns-detail-flex-stake">
                <div className="ns-details-logo">
                  <img src={picTwo} alt="picTwo" className="ns-graph-logo" />
                </div>
                <div className="ns-detail-content">
                  <span>{(plsPerBlock * 8640).toFixed(2)} PLS/Day</span>
                  {/* 8640 Blocks per day */}
                  <span>REWARDS PER NFT</span>
                </div>
              </div>
            </div>
            {/* End Detail Two  */}

            {/*  Detail Three  */}
            <div className="ns-detail-stake">
              <div className="ns-detail-flex-stake">
                <div className="ns-details-logo">
                  <img src={picThree} alt="picThree" />
                </div>
                <div className="ns-detail-content">
                  <span>{anonBalance} PLS</span>
                  <span>BALANCE</span>
                </div>
              </div>
            </div>
            {/* End Detail Three  */}

            {/* End Detail FOur  */}
            <div className="ns-detail-stake">
              <div className="ns-detail-flex-stake">
                <div className="ns-details-logo">
                  <img src={picFour} alt="picFour" />
                </div>
                <div className="ns-detail-content">
                  <span>{pendingRewardTotal.toFixed(2)} PLS</span>
                  <span>PENDING</span>
                </div>
              </div>
            </div>
            {/* End Detail Four */}
          </div>

          {/* Staking Card Sections  */}
          <div className="ns-stack-cards-container">
            {/* Card  */}
            <div className="ns-stack-card">
              <h4>STAKE</h4>
              <div className="ns-nft-content">
                <div style={{ width: "165px", borderRadius: "12px", overflow: "hidden" }}>
                  <img src={stakeImg} alt="StackOne" />
                </div>
                <span className="ns-nft-type">PPC NFTs</span>
                <div className="ns-nft-status">
                  <p>{nftsOnWallet.length} Available</p>
                </div>
              </div>
              <div className="ns-stackbtn-new">
                <button className="ns-stack-nft-btn" onClick={() => setShowModal1(true)}>
                  PPC NFTs to Stake
                </button>
              </div>
            </div>
            {/* End Card  */}
            {/* Card  */}
            <div className="ns-stack-card">
              <h4>STAKE</h4>
              <div className="ns-nft-content">
                <div style={{ width: "165px", borderRadius: "12px", overflow: "hidden" }}>
                  <img src={unStakeImg} alt="StackTWo" />
                </div>
                <span className="ns-nft-type">PPC NFTs</span>
                <div className="ns-nft-status">
                  <p>{nftsOnStaking.length} Staked</p>
                </div>
              </div>
              <div className="ns-stackbtn-new">
                <button className="ns-stack-nft-btn" onClick={() => setShowModal2(true)}>
                  PPC NFTs to Unstake
                </button>
              </div>
            </div>
            {/* End Card  */}
          </div>
          {/* End Staking Card Sections  */}
        </div>
      </div>
      <Footer />

      {/* MOdal Two  */}
      {showModal1 && (
        <div className="ns-stake-overlay">
          <div className="ns-modal-container">
            <div className="ns-modal-heading-flex">
              <div className="ns-modal-heading">
                <h3>MY NFTs</h3>
              </div>
              <button onClick={() => setShowModal1(false)}>✖</button>
            </div>
            {/* NFT Content  */}
            <div className="ns-modal-body">
              <div className="ns-modal-nft-grid">
                {nftsOnWallet.map((item: any, index: number) => {
                  const selectState =
                    selectedNFTsonWallet.filter((item1: any) => Number(item1) === Number(item.id)).length > 0;
                  return (
                    <div className="ns-modal-nft-grid-item">
                      <div className={`${selectState ? "ns-stake-modal-card-select" : "ns-stake-modal-card"}`}>
                        <span>PPC#{item.id}</span>
                        {selectState ? (
                          <div onClick={() => unSelectNFTsonWallet(item.id)}>
                            <img src={item.image} alt="nft" style={{ transform: "scale(0.98)" }}
                            />
                          </div>
                        ) : (
                          <div onClick={() => selectNFTsonWallet(item.id)}>
                            <img src={item.image} alt="nft" />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* NFT Content ENd  */}
            </div>
            <div className="ns-modal-btn">
              {approveState ? (
                <button disabled={stakePendingTx} onClick={handleStake}>
                  {stakePendingTx ? "Process..." : "Stake"}
                </button>
              ) : (
                <button disabled={approvePendingTx} onClick={handleApprove}>
                  {approvePendingTx ? "Process..." : "Approve"}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {/* MOdal Two End */}

      {/* MOdal One  */}
      {showModal2 && (
        <div className="ns-stake-overlay">
          <div className="ns-modal-container">
            <div className="ns-modal-heading-flex">
              <div className="ns-modal-heading">
                <h3>My Staked NFTs</h3>
              </div>
              <button onClick={() => setShowModal2(false)}>✖</button>
            </div>
            {/* NFT Content  */}
            <div className="ns-modal-body">
              <div className="ns-modal-nft-grid">
                {nftsOnStaking.map((item: any, index: number) => {
                  const selectState =
                    selectedNFTsonStaking.filter((item1: any) => Number(item1) === Number(item.id)).length > 0;
                  return (
                    <div className="ns-modal-nft-grid-item">
                      <div className={`${selectState ? "ns-stake-modal-card-select" : "ns-stake-modal-card"}`}>
                        <span>PPC#{item.id}</span>
                        {selectState ? (
                          <div onClick={() => unSelectNFTsonStaking(item.id)}>
                            <img src={item.image} alt="nft" style={{ transform: "scale(0.98)" }}
                            />
                          </div>
                        ) : (
                          <div onClick={() => selectNFTsonStaking(item.id)}>
                            <img src={item.image} alt="nft" />
                          </div>
                        )}
                        <div>
                          <span>{item.pendingReward.toFixed(0)} PLS</span>
                        </div>
                        <span>{!item.isClaimed ? "pending" : "claimed"}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* NFT Content ENd  */}
            </div>
            <div className="ns-modal-btn">
              <button disabled={claimPendingTx} onClick={handleClaim}>
                {claimPendingTx ? "Process..." : "Claim"}
              </button>
              <button disabled={unStakePendingTx} onClick={handleUnStake}>
                {unStakePendingTx ? "Process..." : "UnStake"}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* MOdal One End */}

      {/* {showModal2 && (
        <div className="formMain1">
          <div className="formMain">
            <div style={{ position: "relative", height: "100%", paddingTop: "50px", paddingBottom: "20px" }}>
              <div className="px-glitchtext1" style={{ marginTop: 0 }}>
                My Staked NFTs
              </div>
              <div style={{ height: "72%", overflowY: "auto", marginBottom: "1rem" }}>
                <div className="tokenIds" style={{ marginBottom: 0 }}>
                  {nftsOnStaking.map((item: any, index: number) => {
                    const selectState =
                      selectedNFTsonStaking.filter((item1: any) => Number(item1) === Number(item.id)).length > 0;
                    return (
                      <div className="flip-card" style={{ marginTop: 0, marginBottom: 0 }}>
                        <div className="flip-card-inner">
                          <div className="flip-card-front" style={{ position: "static" }}>
                            <div className={`${selectState ? "selectedrectangle" : "rectangle"}`}>
                              <div className="flip-card-id">PPC#{item.id}</div>
                              {selectState ? (
                                <div
                                  className="unStakeImg"
                                  onClick={() => {
                                    unSelectNFTsonStaking(item.id);
                                  }}
                                >
                                  <img
                                    className="images_show"
                                    src={item.image}
                                    alt="nft"
                                    style={{ transform: "scale(1.02)" }}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="unStakeImg"
                                  onClick={() => {
                                    selectNFTsonStaking(item.id);
                                  }}
                                >
                                  <img src={item.image} alt="nft" />
                                </div>
                              )}
                              <div>{item.pendingReward.toFixed(0)} PLS</div>
                              <div>{!item.isClaimed ? "pending" : "claimed"}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
                <button disabled={claimPendingTx} onClick={handleClaim}>
                  {claimPendingTx ? "Process..." : "Claim"}
                </button>
                <button disabled={unStakePendingTx} onClick={handleUnStake}>
                  {unStakePendingTx ? "Process..." : "UnStake"}
                </button>
              </div>
              {claimPendingTx && (
                <div>
                  <div>
                    <div>Claiming Your Pending Rewards</div>
                  </div>
                </div>
              )}
              {unStakePendingTx && (
                <div>
                  <div>
                    <div>Unstaking Your PPC</div>
                  </div>
                </div>
              )}
              <button onClick={() => setShowModal2(false)}>✖</button>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Staking;
