import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { RefreshContextProvider } from "contexts/RefreshContext";
// import "./App.css";
import "./global.css";
import Home from "views/Home";
import Mint from "views/Mint";
import Referral from "views/Referral";
import Earn from "views/Earn";
import Staking from "views/Staking";
import { useWeb3Context } from "hooks/useWeb3Context";
import About from "views/About";
import Work from "views/Work";
// import Reveal from 'views/Reveal';
// import About from 'views/About'
// import DAO from 'views/DAO'
// import Marketplace from 'views/Marketplace'

const App = () => {
  const [reload, setReload] = useState(false);
  const web3Context = useWeb3Context();
  // useAllNFTUserData(web3Context?.account, reload)

  return (
    <RefreshContextProvider>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "topZIndex",
          duration: 5000,
          style: {
            background: "#586285",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
          },
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<About />} />
          <Route path="how-it-works" element={<Work />} />
          {/* <Route path="/" element={<Mint />} /> */}
          <Route path="mint" element={<Mint />} />
          <Route path="/mint/:referral" element={<Mint />} />
          <Route path="stake" element={<Staking />} />

          {/* <Route path="/stake/abstraction" element={<StakeAbstraction reloadNftData={reload} setReloadNftData={setReload} /> } />
          <Route path="/stake/wiainitai" element={<StakeWiainitai reloadNftData={reload} setReloadNftData={setReload} /> } />
          <Route path="/stake/koba" element={<Stake reloadNftData={reload} setReloadNftData={setReload} /> } /> */}
          <Route path="/referral" element={<Referral />} />
          {/* <Route path="/earn" element={<Earn /> } /> */}

          {/* <Route path="/dao" element={<DAO /> } />
          <Route path="/about" element={<About /> } />
          <Route path="/marketplace" element={<Marketplace /> } />
          <Route path="/reveal" element={<Reveal reloadNftData={reload} setReloadNftData={setReload} /> } /> */}
        </Routes>
      </BrowserRouter>
    </RefreshContextProvider>
  );
};

export default App;
