import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import { SUBGRAPH_URL } from 'config';
import { ethers } from 'ethers';
import { useWeb3Context } from 'hooks/useWeb3Context';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import {FiCopy} from 'react-icons/fi'
import { callClaimReferralEarning } from 'utils/calls';
import { getKobaContract } from 'utils/contractHelpers';
import Header from 'views/Header';
import logo from '../../assets/logo.png';

interface ReferralMint {
    minter: string;
    amount: string;
    createdAt: number;
}

const mintQuery = `
    query MyQuery($referrer: Bytes!) {
        mints(where: {referrer: $referrer}) {
            minter
            amount
            createdAt
        }
    }
`

const claimQuery = `
    query MyQuery($referrer: Bytes!) {
        claims(where: {referrer: $referrer}) {
            amount
            createdAt
        }
    }
`

const Referral = () => {
    const web3Context = useWeb3Context()
    const [loadingReferralMint, setLoadingReferralLint] = useState<"idle" | "loading" | "loaded" | "failed">("idle")
    const [referralMint, setReferralMint] = useState<ReferralMint[]>([])
    const [referralLink, setReferralLink] = useState("")
    const [tweetUrl, setTweetUrl] = useState("")
    // const [claimedAmount, setClaimedAmount] = useState(0)
    // const [claimableAmount, setClaimableAmount] = useState(0)
    const [pendingTx, setPendingTx] = useState(false)

    // useEffect(() => {
    //     const fetchClaimableAmount =async (account: string, provider: any) => {
    //         const contract = getKobaContract(provider)
    //         const _claimableAmount = ethers.utils.formatEther(await contract.methods.referrerEarning(account).call())
    //         setClaimableAmount(Number(_claimableAmount))
    //     }
    //     if(web3Context?.account && web3Context?.provider) {
    //         fetchClaimableAmount(web3Context?.account, web3Context?.provider)
    //     }
    // }, [web3Context?.account, web3Context?.provider])

    // useEffect(() => {
    //     const fetchClaimedAmount = async (account: string) => {
    //         try {
    //             // setLoadingReferralLint("loading")
    //             const client = new ApolloClient({
    //             uri: SUBGRAPH_URL,
    //             cache: new InMemoryCache(),
    //             });
                
    //             const { data: result, error: error } = await client.query({
    //                 query: gql(claimQuery),
    //                 variables: {
    //                     referrer: account
    //                 },
    //                 fetchPolicy: "cache-first",
    //             });
    //             console.log("sniper: result1: ", result)
    //             if (result && !error) {
    //                 const _claims = result.claims.map((item: any) => {return {
    //                     amount: Number(ethers.utils.formatEther(item.amount)),
    //                     // createdAt: Number(item.createdAt),
    //                 }})
    //                 console.log("sniper: _claims: ", _claims)
    //                 const _claimedAmount = (_claims && _claims.length > 0)? _claims.map((item: any) => item.amount).reduce((a:any, b:any) => Number(a) + Number(b), 0) : 0
    //                 console.log("sniper: _claimedAmount: ", _claimedAmount)
    //                 setClaimedAmount(_claimedAmount)
    //             }
    //             setLoadingReferralLint("loaded")
    //         } catch (error) {
    //             console.error(error)
    //             setLoadingReferralLint("failed")
    //         }
    //     }
    //     if(web3Context?.account) {
    //         fetchClaimedAmount(web3Context.account)
    //     }
    // }, [web3Context?.account])

    const [totalNFTsUnderYou, settotalNFTsUnderYou] = useState(0)

    useEffect(() => {
        const fetchReferralMint = async (account: string) => {
            try {
                setLoadingReferralLint("loading")
                const client = new ApolloClient({
                uri: SUBGRAPH_URL,
                cache: new InMemoryCache(),
                });
                
                const { data: result, error: error } = await client.query({
                    query: gql(mintQuery),
                    variables: {
                        referrer: account
                    },
                    fetchPolicy: "cache-first",
                });
                
                if (result && !error) {
                    const _referralMints = result.mints.map((item: any) => {return {
                        minter: item.minter,
                        amount: Number(item.amount),
                        createdAt: Number(item.createdAt),
                    }})
                    setReferralMint(_referralMints)
                    if(_referralMints.length > 0) {
                        const _totalNFTsUnderYou = _referralMints.map((item: any) => item.amount).reduce((prev: any, next: any) => Number(prev) + Number(next))
                        settotalNFTsUnderYou(_totalNFTsUnderYou)
                    } else {
                        settotalNFTsUnderYou(0)
                    }
                }
                setLoadingReferralLint("loaded")
            } catch (error) {
                console.error(error)
                setLoadingReferralLint("failed")
            }
        }
        if(web3Context?.account) {
            fetchReferralMint(web3Context.account)
        }
    }, [web3Context?.account])

    useEffect(() => {
        if(web3Context?.account) {
            setReferralLink(`${window.location.origin}/mint?ref=${web3Context?.account}`)
            setTweetUrl(`https://twitter.com/intent/tweet?url=%20My%20Copilot%20Bae%3A%20%20Woke%20Captains%20+%20Friends%20of%20Bae%20PFP%20NFT%20minting%20now%0A%0AJoin%20the%20Journey%20to%20the%20Moon%20with%20Bae 
            DEX%2C%20WAIFU%2C%20CHILL%20%28%2764%2C%1F4B0%29%0A%0AReferral%20link%3A%20https%3A%2F%2Fmint%2Emycopilotbae%2Ecom%2F${web3Context?.account.toLowerCase()}%0A%0A%23mycopilotbae%20%23eth%20%23nfts%20%23nftcommunity`)
        }
    }, [web3Context?.account])

    const handleCopyReferralLink = (text: string) => {
        navigator.clipboard.writeText(text)
        .then(() => {
        //   alert('Copied to clipboard!');
          toast.success("Copyed Successfully.")
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const handlePostTweet = async () => {
        window.open(tweetUrl);
    }

    return (
        <div className="stakeMainPage">
          <div className="aboutPageMain">
              <Header />
    
              <div className="aboutPageMain2">
                  <div className="glitch" data-text="Referral System">Referral System</div>
              </div>
    
              <div className="aboutP" style={{marginTop: "50px"}}>
                <div className='flexBetween'>
                    <p>Your Referral Link</p>
                    <div className='flexCenter'>
                        {web3Context?.account ? <p style={{color: "#00E6F6"}}>{`${referralLink.substring(0, 15)}...${referralLink.substring(referralLink.length-15, referralLink.length)}`}</p> : <p style={{color: "yellow"}}>Confirm Your Wallet Connection</p>}
                        {web3Context?.account && <FiCopy onClick={() => handleCopyReferralLink(referralLink)} style={{marginLeft: "5px"}} size={24} color="#00E6F6" />}
                    </div>
                </div>
                <div className='flexEnd'>
                    <button className="kave-btn"  onClick={handlePostTweet}>
                        <span className="kave-line"></span>
                        Post Tweet
                    </button>
                </div>
                
                {/* <p className="cLine"></p>

                <div className='flexBetween'>
                    <p>Total Claimed Amount</p>
                    <div className='m-balance'>{claimedAmount} (ETH)</div>
                </div>
                <div className='flexBetween'>
                    <p>Total Claimable Amount</p>
                    <div className='m-balance'>{claimableAmount} (ETH)</div>
                </div>

                <div className='flexEnd'>
                    <button className="kave-btn"  onClick={handleClaim}>
                        <span className="kave-line"></span>
                        Claim Now
                    </button>
                </div> */}
                
                <p className="cLine"></p>

                <div className='flexBetween'>
                    <p>Total NFTs minted under your Referral link</p>
                    <div className='m-balance'>{totalNFTsUnderYou}</div>
                </div>
                <p className="cLine"></p>

                <p>Referral Mint Events</p>
                <table className='referralTable'>
                    <thead>
                        <tr>
                        <th>Date</th>
                        <th>Referrer</th>
                        <th>Amount</th>
                        {/* <th>Your Earning</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {(referralMint && referralMint.length > 0) && referralMint.map((item: any, index: any) => {
                            const date = new Date(item.createdAt * 1000).toDateString();
                            const referrer = item.minter;
                            const amount = item.amount;
                            const earning = Number((item.amount * 0.1 * 0.1).toFixed(5))
                            return (
                                <tr key={index}>
                                    <td>{date.toString()}</td>
                                    <td>
                                        <div className='flexCenter'>
                                            <p style={{color: "#00E6F6"}}>{`${referrer.substring(0, 5)}...${referrer.substring(referrer.length-10, referrer.length)}`}</p>
                                            <FiCopy onClick={() => handleCopyReferralLink(referrer)} style={{marginLeft: "5px"}} size={24} color="#00E6F6" />
                                        </div></td>
                                    <td>{amount}</td>
                                    {/* <td>{earning}</td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                    
                {(!referralMint || referralMint.length <= 0) && <div className='flexCenter'><p>Nothing to display...</p></div>}
            </div>
    
            <div className="cMain">
                <p className="cLine"></p>
                <img src={logo} alt="logo"/>
                <div style={{marginTop: "10px"}}>© 2023 PulsePixel Cartel NFTs. All rights reserved.</div>
                <p className="cLine"></p>
            </div>
          </div>
    
      </div>
      )
}

export default Referral