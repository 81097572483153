import { useWeb3Context } from "hooks/useWeb3Context";
import React, { useState } from "react";
import { Link, NavLink, useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/logo.png";
import NewLogo from "../../assets/static/imgs/new-logo.png";
import MenuBtn from "../../assets/static/imgs/Menu Button.png";

const discord = () => {
  window.open("https://discord.gg/byanon");
};

const twitter = () => {
  window.open("https://twitter.com/A__________by_A");
};

const linkStyle = {
  textDecoration: "none",
};

const Header = () => {
  const web3Context = useWeb3Context();
  const [tab, setTab] = useState("/mint");
  const location = useLocation();
  const [ShowMenus, setShowMenus] = useState(false);

  //  *********************** OLD Source Code  ******************************

  // return (
  //   <div className="navBarStake">
  //     <div className="navBarStake2">
  //       {/* <a className="tName" href='http://www.bullheadednfts.io/' target="_blank" rel="noreferrer"> */}
  //         <img className="logoNFT" src={logo} alt="logo"/>
  //       {/* </a> */}

  //       <div className="connect">
  //         {/* <Link to="/referral" style={linkStyle} id={location.pathname === "/referral" ? "fontSizeActive" : "fontSize"} >Referral</Link> */}
  //         <Link to="/mint" style={linkStyle} id={location.pathname === "/mint" ? "fontSizeActive" : "fontSize"}>Mint</Link>
  //         <Link to="/stake" style={linkStyle} id={location.pathname === "/stake" ? "fontSizeActive" : "fontSize"}>Stake</Link>
  //         {/* <Link to="/earn" style={linkStyle} id={location.pathname === "/earn" ? "fontSizeActive" : "fontSize"} >Earn</Link> */}
  //         {/* <Link to="/reveal" style={linkStyle} id={location.pathname === "/reveal" ? "fontSizeActive" : "fontSize"}>Burn KEEP OUT</Link> */}
  //         {/* <Link to="/about" style={linkStyle} id={location.pathname === "/about" ? "fontSizeActive" : "fontSize"} >About</Link> */}
  //         {/* <Link to="/marketplace" style={linkStyle} id={location.pathname === "/marketplace" ? "fontSizeActive" : "fontSize"} >Marketplace</Link> */}
  //         {/* <Link to="/dao" style={linkStyle} id={location.pathname === "/dao" ? "fontSizeActive" : "fontSize"} >DAO</Link> */}
  //         {/* <div id={location.pathname === "/twitter" ? "fontSizeActive" : "fontSize"} onClick={twitter}>Twitter</div>
  //         <div id={location.pathname === "/discord" ? "fontSizeActive" : "fontSize"} onClick={discord}>Discord</div> */}
  //       </div>
  //       <div className="connectMobile">
  //         {/* <Link to="/referral" style={linkStyle} id={location.pathname === "/referral" ? "fontSizeActive" : "fontSize"} >Referral</Link> */}
  //         <Link to="/mint" style={linkStyle} id={location.pathname === "/mint" ? "fontSizeActive" : "fontSize"} >Mint</Link>
  //         <Link to="/stake" style={linkStyle} id={location.pathname === "/stake" ? "fontSizeActive" : "fontSize"} >Stake</Link>
  //         {/* <Link to="/earn" style={linkStyle} id={location.pathname === "/earn" ? "fontSizeActive" : "fontSize"} >Earn</Link> */}
  //         {/* <Link to="/reveal" style={linkStyle} id={location.pathname === "/reveal" ? "fontSizeActive" : "fontSize"} >Burn KEEP OUT</Link> */}
  //         {/* <Link to="/about" style={linkStyle} id={location.pathname === "/about" ? "fontSizeActive" : "fontSize"} >About</Link> */}
  //         {/* <Link to="/marketplace" style={linkStyle} id={location.pathname === "/marketplace" ? "fontSizeActive" : "fontSize"} >Marketplace</Link> */}
  //         {/* <Link to="/dao" style={linkStyle} id={location.pathname === "/dao" ? "fontSizeActive" : "fontSize"} >DAO</Link> */}
  //       </div>

  //       <div className="walletAndMint">
  //         {!web3Context?.account ?
  //           (
  //             <button className="kave-btn"  onClick={web3Context?.connectWallet}>
  //               <span className="kave-line"></span>
  //               Connect
  //             </button>
  //           ) : (
  //             <button className="kave-btn" onClick={web3Context?.disconnect}>
  //               <span className="kave-line"></span>
  //               {web3Context?.account.slice(0, 3) + "..." + web3Context?.account.slice(39, 42)}
  //             </button>
  //           )}
  //       </div>
  //     </div>
  //   </div>
  // )
  return (
    <header>
      <div className="ns-new-container">
        <div className="ns-header-sp">
          <div className="ns-myFlex">
            <Link to="/">
              <img src={NewLogo} alt="" className="ns-newlogo" />
            </Link>
            <ul className={ShowMenus ? "ns-mob-hidden ns-active" : "ns-mob-hidden"}>
              <li>
                <NavLink
                  to="/"
                  style={({ isActive }) => {
                    return { color: isActive ? "#6E4B86" : "#ffffff" };
                  }}
                >
                  HOME
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about-us"
                  style={({ isActive }) => {
                    return { color: isActive ? "#6E4B86" : "#ffffff" };
                  }}
                >
                  ABOUT US
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/how-it-works"
                  style={({ isActive }) => {
                    return { color: isActive ? "#6E4B86" : "#ffffff" };
                  }}
                >
                  HOW IT WORKS
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/mint"
                  style={({ isActive }) => {
                    return { color: isActive ? "#6E4B86" : "#ffffff" };
                  }}
                >
                  MINT
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/stake"
                  style={({ isActive }) => {
                    return { color: isActive ? "#6E4B86" : "#ffffff" };
                  }}
                >
                  STAKE
                </NavLink>
              </li>
              <li className="ns-hidden-on-desk">
                {!web3Context?.account ?
                  (
                    <button className="ns-header-btn-sec" onClick={web3Context?.connectWallet}>
                      CONNECT
                    </button>
                  ) : (
                    <button className="ns-header-btn-sec" onClick={web3Context?.disconnect}>
                      {web3Context?.account.slice(0, 3) + "..." + web3Context?.account.slice(39, 42)}
                    </button>
                  )}
                <button className="ns-menus-btn-close" onClick={() => setShowMenus(!ShowMenus)}>
                  ✖
                </button>
              </li>
            </ul>
            {!web3Context?.account ?
              (
                <button className="ns-header-btn" onClick={web3Context?.connectWallet}>
                  CONNECT
                </button>
              ) : (
                <button className="ns-header-btn" onClick={web3Context?.disconnect}>
                  {web3Context?.account.slice(0, 3) + "..." + web3Context?.account.slice(39, 42)}
                </button>
              )}
            <button className="ns-menus-btn" onClick={() => setShowMenus(!ShowMenus)}>
              <img src={MenuBtn} alt="MenuBtn" />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
