import { zeroAddress } from "ethereumjs-util/dist/account";
import { ethers } from "ethers";
import keccak256 from "keccak256";
import { MerkleTree } from "merkletreejs";
import { useWeb3Context } from "hooks/useWeb3Context";
import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useParams, useSearchParams } from "react-router-dom";
import { callMintNFT } from "utils/calls";
import { getKobaContractWithoutSigner } from "utils/contractHelpers";
import Header from "views/Header";
import whitelist from "config/whitelist";

import logo from "../../assets/logo.png";
import mintimg from "../../assets/static/imgs/mint-img.png";
import Footer from "components/Footer";

const whitelistLeafNodes = whitelist.map((addr) => keccak256(addr));
const whitelistMerkleTree = new MerkleTree(whitelistLeafNodes, keccak256, { sortPairs: true });

const Mint = () => {
  const web3Context = useWeb3Context();

  const [referralAddress, setReferralAddress] = useState<string>("");
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [count, setCount] = useState(1);

  const [pendingAnon, setPendingAnon] = useState(false);
  const [minted, setMinted] = useState(0);
  const [totalSupply, setTotalsupply] = useState(0);

  const [reload, setReload] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const checkReferralAddress = (ref: any) => {
      if (ethers.utils.isAddress(ref)) {
        if (web3Context?.account && web3Context.account.toLowerCase() === ref.toLowerCase()) {
          setInvalidAddress(true);
        } else {
          setReferralAddress(ref);
          setInvalidAddress(false);
        }
      } else {
        setInvalidAddress(true);
      }
    };
    if (searchParams.get("ref")) {
      checkReferralAddress(searchParams.get("ref"));
    }
  }, [searchParams, web3Context?.account]);

  useEffect(() => {
    const fetchMinted = async () => {
      try {
        const kobaContract = getKobaContractWithoutSigner();
        const _minted = await kobaContract.mintCounter();
        setMinted(Number(_minted));
        const _totalSupply = await kobaContract.MAX_NFT_SUPPLY();
        console.log("sniper: total supply: ", _totalSupply);
        setTotalsupply(Number(_totalSupply));
      } catch (error) {
        console.log(error);
      }
    };
    fetchMinted();
  }, [reload]);

  const [pauseMint, setPauseMint] = useState(true);
  const [mintStep, setMintStep] = useState("");
  const [mintLimit, setMintLimit] = useState(10);
  const [mintPrice, setMintPrice] = useState(0);
  const [merkleRoot, setMerkleRoot] = useState(0);
  useEffect(() => {
    const fetchMintStatus = async () => {
      try {
        const kobaContract = getKobaContractWithoutSigner();
        const _status = await kobaContract.getMintState();
        console.log("sniper: mint status: ", _status);
        setPauseMint(_status["_mintPause"]);
        setMintStep(_status["_mintStep"]);
        // setMintLimit(_status['_mintLimit'])
        setMintPrice(Number(ethers.utils.formatEther(_status["_mintPrice"])));
        setMerkleRoot(_status["_merkleRoot"]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMintStatus();
  }, []);

  const handleMinus = () => {
    if (count < 2) return;
    setCount(count - 1);
  };

  const handlePlus = () => {
    if (count + 1 > mintLimit) return;
    setCount(count + 1);
  };

  const handleMint = async () => {
    if (!web3Context?.account) {
      toast.error("Confirm your wallet connection!");
      return;
    }
    if (pauseMint) {
      toast.error("Mint paused!");
      return;
    }
    console.log("sniper: merklerooot: ", merkleRoot);
    if (Number(merkleRoot) !== 0) {
      const isWhitelist = whitelist.map((item: any) => item.toLowerCase()).includes(web3Context.account.toLowerCase());
      if (!isWhitelist) {
        toast.error("You are not a whitelist.");
        return;
      }
    }
    setPendingAnon(true);

    const hexAddress = keccak256(web3Context.account);
    let hexProof = ["0x0"];
    if (Number(merkleRoot) !== 0) {
      hexProof = whitelistMerkleTree.getHexProof(hexAddress);
    }
    const result = await callMintNFT(
      web3Context?.provider,
      web3Context?.account,
      hexProof,
      count,
      mintPrice,
      invalidAddress ? "" : referralAddress
    );

    setPendingAnon(false);
    if (result) {
      toast.success("Mint success!");
      setReload(!reload);
    } else {
      toast.error("Something Went Wrong!");
    }
  };

  //   const [days, setDays] = useState(0);
  //   const [hours, setHours] = useState(0);
  //   const [minutes, setMinutes] = useState(0);
  //   const [seconds, setSeconds] = useState(0);

  //   useEffect(() => {
  //     let timeRemaining = saleConfig.whitelistStart * 1000 - new Date().getTime()
  //     console.log("sni[er: rrrrr: ", timeRemaining, saleConfig.whitelistStart)
  //     setCountdownFor(timeRemaining);

  //     const interval = setInterval(() => {
  //       if (timeRemaining > 1000) {
  //         timeRemaining -= 1000;
  //         setCountdownFor(timeRemaining);
  //       }

  //       if (timeRemaining <= 1000) {
  //         clearInterval(interval);
  //       }
  //     }, 1000);

  //     return () => {
  //       clearInterval(interval);
  //     };
  //   }, [saleConfig.whitelistStart]);

  //   const setCountdownFor = (deadline: number) => {
  //     const _days = Math.floor(deadline / (1000 * 60 * 60 * 24));
  //     const _hours = Math.floor((deadline / (1000 * 60 * 60)) % 24);
  //     const _minutes = Math.floor((deadline / 1000 / 60) % 60);
  //     const _seconds = Math.floor((deadline / 1000) % 60);

  //     setDays(_days <= 0 ? 0 : _days);
  //     setHours(_hours <= 0 ? 0 : _hours);
  //     setMinutes(_minutes <= 0 ? 0 : _minutes);
  //     setSeconds(_seconds <= 0 ? 0 : _seconds);
  //   };

  //  *********************** OLD Source Code  ******************************

  //     return (
  //         <div className="allWrap">
  //             <div className="mint2">
  //                 <Header />
  //                 <div className="vidCont">
  //                     {/* <video className="videoBg" autoPlay loop muted playsInline>
  //                         <source src="images/bg.mp4" type="video/mp4" />
  //                         Sorry, your browser doesn't support videos.
  //                     </video> */}
  //                     <img className="videoBg" src='images/bg.png' alt='bg' />
  //                 </div>
  // {/*
  //                 {saleState === "non" && <div className='flexCenter' style={{flexDirection: "column", marginTop: "150px", marginBottom: "200px"}}>
  //                     <div style={{zIndex: "1", color: "white", textAlign: "center", marginBottom: "20px", marginTop: "50px"}}>Whitelist Sale will begin in</div>
  //                     <div className='time-container'>
  //                         <div className="time">
  //                             <div className="day">
  //                                 {days}
  //                             </div>
  //                             <p className="unit">
  //                                 Days
  //                             </p>
  //                         </div>
  //                         <div className="time">
  //                             <div className="day">
  //                                 {hours}
  //                             </div>
  //                             <p className="unit">
  //                                 Hours
  //                             </p>
  //                         </div>
  //                         <div className="time">
  //                             <div className="day">
  //                                 {minutes}
  //                             </div>
  //                             <p className="unit">
  //                                 Minutes
  //                             </p>
  //                         </div>
  //                         <div className="time">
  //                             <div className="day">
  //                                 {seconds}
  //                             </div>
  //                             <p className="unit">
  //                                 Seconds
  //                             </p>
  //                         </div>
  //                     </div>

  //                 </div>} */}

  //                 <div className="introduction">
  //                     <div>
  //                         <div className="mintDiv">

  //                         <div className="px-app px-glitch">
  //                             <span className="px-glitchtext px-glitchtext-anim" style={{fontSize: "18px"}}>{mintStep}</span>
  //                             <span className="px-glitchtext px-glitchtext-anim">PulsePixel Cartel Mint</span>
  //                         </div>
  //                         <div className="totalSupply">{minted} / {totalSupply}</div>
  //                         <div className="price"><div>Mint Price: {Number((mintPrice * count).toFixed(5))} PLS</div></div>
  //                         {/* <div className="price"><div>Mint Limit: {Number(mintLimit)}</div></div> */}

  //                         <div className='m-flex-div'>
  //                             {/* <video className="m-video" autoPlay loop muted playsInline>
  //                                 <source src="images/bg.mp4" type="video/mp4" />
  //                                 Sorry, your browser doesn't support videos.
  //                             </video> */}
  //                             <img className="m-video" src="images/mint.png" alt="logo"/>
  //                         </div>

  //                         <div className="minting_count_button">
  //                             <div className="center">
  //                                 <button className="btnfos-0-2" type="submit" onClick={handleMinus}>-</button>
  //                             </div>

  //                             <div className="nftminter2Div">
  //                             <div className="nftminter2">{count}</div>
  //                             </div>

  //                             <div className="center">
  //                                 <button className="btnfos-0-2-2" type="submit" onClick={handlePlus}>+</button>
  //                             </div>
  //                         </div>

  //                         <div className="mintbuttondiv">
  //                             <button className="button-92" type="submit" onClick={handleMint}>Mint Now</button>
  //                         </div>
  //                         <div style={{marginTop: "5px", textAlign: "center"}}>
  //                             {pendingAnon && <div className="loadingText">Minting your PulsePixel Cartel</div>}
  //                             {invalidAddress && <h4 style={{color: "#ffeb00"}}>Warning: Invalid Referral Address</h4>}
  //                         </div>

  //                         </div>
  //                     </div>

  //                 </div>

  //                 <div className="cMain">
  //                     <p className="cLine"></p>
  //                     <img src={logo} alt="logo"/>
  //                     <div style={{marginTop: "10px"}}>© 2023 PulsePixel Cartel NFTs. All rights reserved.</div>
  //                     <p className="cLine"></p>
  //                 </div>
  //             </div >
  //         </div >
  //     )

  return (
    <div className="ns-main-sec">
      <Header />
      <div className="ns-new-container-sm">
        <div className="ns-mint-main-wrap">
          <h3 className="ns-mint-heading-main">PULSEPIXEL CARTEL MINT</h3>
          <div className="ns-mint-sec">
            <div className="ns-mint-fields">
              <span>{minted}</span>
              <p>/</p>
              <span>{totalSupply}</span>
            </div>
            <div className="ns-mint-heading">
              <h4>MINT PRICE</h4>
              <span>{Number((mintPrice * count).toFixed(5))} PLS</span>
            </div>
            <div className="ns-mint-counter">
              <div className="mint-counter-img">
                <img src={mintimg} alt="mintimg" />
              </div>
              <div className="ns-mint-cb">
                <button onClick={handlePlus}>
                  <span>+</span>
                </button>
                <input type="text" readOnly value={count} />
                <button onClick={handleMinus}>
                  <span>-</span>
                </button>
              </div>
            </div>
            <div className="ns-mint-btn-new">
              <button onClick={handleMint}>MINT NOW</button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Mint;
