import { Link } from "react-router-dom";
import NewFooterLogo from "../assets/static/imgs/new-footer-logo.png";
import TwitterXLogo from "../assets/static/imgs/TwitterX.png";
import DiscordLogo from "../assets/static/imgs/Discord New.png";
import TelegramLogo from "../assets/static/imgs/Telegram App.png";

const Footer = () => {
  return (
    <footer>
      <div className="ns-new-container">
        <div className="ns-footer-myFlex">
          <Link to="/" className="ns-footer-logo">
            <img src={NewFooterLogo} alt="NewFooterLogo" />
          </Link>
          <div className="ns-footer-links ns-footer-desk">
            <h4>Features</h4>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">About</Link>
              </li>
              <li>
                <Link to="/how-it-works">How it works</Link>
              </li>
            </ul>
          </div>
          <div className="ns-footer-links ns-footer-desk">
            <h4>Company</h4>
            <ul>
              <li>
                <Link to="/">Pulse Pixel Cartel</Link>
              </li>
              <li>
                <Link to="/">Privacy policy</Link>
              </li>
              <li>
                <Link to="/">Terms of Use</Link>
              </li>
            </ul>
          </div>
          <div className="ns-footer-links">
            <h4>Join us</h4>
            <div className="ns-footer-socials">
              <a
                href=" https://x.com/PLSPixelCartel?t=8se31QDKPqoZp_Q6xDSjUA&s=09"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={TwitterXLogo} alt="TwitterXLogo" />
              </a>
              <a href="https://discord.com/invite/Re4UXzaJ" target="_blank" rel="noopener noreferrer">
                <img src={DiscordLogo} alt="DiscordLogo" />
              </a>
              <a href="https://t.me/PulsePixelCartel" target="_blank" rel="noopener noreferrer">
                <img src={TelegramLogo} alt="TelegramLogo" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* CopyRights  */}
      <div className="ns-copy-right">
        <p>All Rights Reserved. Pulse Pixel Cartel 2023</p>
      </div>
    </footer>
  );
};

export default Footer;
