import Header from "views/Header";
import RightImg from "../../assets/static/imgs/how-works-img-right.png";
import Footer from "components/Footer";

const Work = () => {
  return (
    <div className="ns-main-sec">
      <Header />
      <div className="ns-new-container">
        <div className="ns-flex-work">
          {/* Left Side  */}
          <div className="ns-work-left">
            <h3>HOW IT WORKS</h3>
            <div>
              <p>Secure rewards by staking your NFT. Rewards stem from 10% royalties and 10% of minting fees.</p>
              <p>However, here&apos;s the twist – once you claim your rewards, they cease.</p>
              <p>
                Possess a stake in the minting fees through your NFT. Each NFT bestows upon you a share of the minting
                fees and royalties, enabling you to generate passive income.
              </p>
              <p>
                The decision is yours: claim your accrued fees at your discretion, but bear in mind that each NFT can
                only claim once. While this means forfeiting eligibility for future rewards, it enhances the value of
                the remaining collection as they collectively partake in the profits. This dynamic introduces a
                compelling strategy: either hold and accumulate or claim and relinquish future rewards..
              </p>
            </div>
          </div>
          {/* Right Side  */}
          <div className="ns-work-right">
            <img src={RightImg} alt="RightImg" />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Work;
