const whitelist = [
    '0x31C9c2AB59b585c5F57BCe8F770263154f64725C',
    '0xD4CAF91d5b4eF2BEb502621EC665f8b48b5ac6C7',
    '0x47A0A7653a39643240d7b11854e68b2dAeA9002B',
    '0xE2D6F666ECa488d077766d80C1923F34F5C0a4cc',
    '0x1ED68F3a345be78C43613762076A4fAB69132c9a',
    '0x0E6FeBaC8b596d952689b6813Ea2cDA54CEee6eC',
    '0xf5B5ab9E9Dac037aC47daBa0B34BC76456A0DCa4',
    '0x6c6a24A35225891735c259299Fc0B1Ff0d781835',
    '0x2412B8130d55E3E1353BE78dDd568941293eEb75',
    '0x29E6d8b995C55767011adeeb1CB1d1Ed978BE451',
    '0x1e18ed1bfCa02e59a43de32c60Ac0FD4923b64b5',
    '0x15DF35a239D3AD97fc6b8d3AB5910C8c29eCa0B6',
    '0x1470a553DC90A9D3cC94A2Ab09253F14Ed7Dafd8',
    '0x2Df4cC7cc3a2E0beC557681aC0831a0a51E9b71c',
    '0xD59790EBbCC620bb9De759e25c0CCBD4d1B60f17',
    '0x40D3753dAd6EE55422482F92F3f3B8C52ddBD06f',
    '0xd0d77FB8A8CbE77bF3Bb810615A2E0901e77A3Fc',
    '0x03a92F5a5060B83ebD11054C03973A28558f1A9e',
    '0x39Ca97BEB340f8C352dDfE7Db0d3E0D1E1b3674D',
    '0x9f7F78ee5cA4F4efb5a30C05b82F21CC48AabeF7',
    '0x4D113291a2924F920885B48814e82395425449b9',
    '0x5DB8A47ed3C76522ECD1Fcf7a79136Cae186dFc1',
    '0xEeCB09BBE9b07eA02355a1B5c6AF08C1439f1089',
    '0x928Bdc02468439188D05a0b5497d895db4231248',
    '0xA5097b048Aa2C140A54d426E3B9eA550B6CC9B4a',
    '0xDc6085035e331d7289126fDD27bAFD84feC5DC67',
    '0x144725fec8B6D9E1DC3d91717cFAce6C19D22ffB',
    '0xe7bB73646Dc80414930fC549cE5Ba37e987f8039',
    '0x4002C8aD8cAE3c64BbdFFa17bB30eD598C75A192',
    '0xb5582773150BBc69F2e06c7f097672B1a73c0Ead',
    '0xeC60153Ab34f84c7973fb7527f5A86Cd015fBdcA',
    '0xA7a3F35a1be897AFdB6770D7dC16dFe71E951862',
    '0x871A6229B054f4Ea12d1f5EA2C26bd63EaC0304d',
    '0xd1353097131Df77A0Ec3a7EB939e319da3FF93E2',
    '0x28722cFa560B259DdBdA898a134F57956EDeEb82',
    '0x28e9af8Be992267f2c545E792C7f267Fcad8a664',
    '0xf5b8EA6160bc1E702B54d662c3582287fa8DFfFC',
    '0xc2ef1173a4B051BbCc4474c407BBb2ac7E02e363',
    '0xA3c8B26c7FF2a58A94C6e3d78716b7ed3402564f',
    '0xcE4b433851d45340c2c6CE36c1bAc35Ad5Bded11',
    '0x7f107fA5bD5Bc78d784B2089637E740675f23470',
    '0x184e330a18e490b64f629c284c9024A83327EBEf',
    '0x412EA257d251249464c4CfE0606C1626D8B24001',
    '0x406D36c8F574dFC142BD09d9CEEc7aFbEB391c43',
    '0x0F2ed1a67d94a7Fe8c6543FFDb0a2dD7FF288C46',
    '0xA71BED5e55C43cDd42415fB22A6eEcFFa4c7B2Cd',
    '0x7f9D4933B43154f68d16Dfa3bDf50bb49CD8e194',
    '0x8EB4E53e1e81011dcb818f6c2448c928C2e0e00e',
    '0x043EA8fF33d420f72A66b493e11d3DFFf062a7d9',
    '0x79bF395222388d1BD5a0eb867ab364Df2c053F80',
    '0x6A8f6d78fE31fc76dC384D1cC824E4e7eA365910'
    ];

export default whitelist