import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'state'
import { fetchAllNFTsonWallet, fetchNFTsonStaking, fetchNFTsonWallet } from './fetchNFTUser'

export interface NFTData {
  id: number
  name: string
  image: string
}

export interface FamousData {
  name: string
  address: string
  price: number
  amount: number
  tokenIds: number[]
}

interface NFTUserDataResponse {
  collection: string
  nfts: NFTData[]
  stakedNfts: NFTData[]
  plsPerBlock: number
}

interface NFTAllUserDataResponse {
  famous: FamousData,
  nfts: NFTData[],
  stakedNfts: NFTData[],
  plsPerBlockForKoba: number,
}

export interface UserState {
  famous: FamousData,
  nfts: NFTData[],
  stakedNfts: NFTData[],
  approveFornfts: boolean,
  plsPerBlockForKoba: number,
  rewardTokenBalance: number,
  status: 'idle' | 'loading' | 'failed'
}

const initialState: UserState = {
  famous: {name: '', address: '', price: 0, amount: 0, tokenIds: []},
  nfts: [],
  stakedNfts: [],
  approveFornfts: false,
  plsPerBlockForKoba: 0,
  rewardTokenBalance: 0,
  status: 'idle',
}

export const fetchUserDataAsync = createAsyncThunk<NFTUserDataResponse, {collection: string, account: string}, {state: RootState}>(
  'user/fetchNFTUserData',
  async ({ collection, account }) => {
    const nfts = await fetchNFTsonWallet(collection, account)
    const stakingInfo = await fetchNFTsonStaking(collection, account)
    return {
      collection: collection,
      nfts: nfts,
      stakedNfts: stakingInfo.stakedNfts,
      plsPerBlock: stakingInfo.plsPerBlock
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateNFTUserDataWithEmpty: (state) => {
      state.nfts = []
      state.stakedNfts = []
    },
    updateApprovalStatus: (state, action) => {
      if(action.payload.collection === "koba") {
        state.approveFornfts = action.payload.approveStatus
      }
    },
    updateRewardTokenBalace: (state, action) => {
      state.rewardTokenBalance = action.payload.balance
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDataAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchUserDataAsync.fulfilled, (state, action) => {
        if(action.payload.collection === "koba") {
          state.nfts = action.payload.nfts
          state.stakedNfts = action.payload.stakedNfts
          state.plsPerBlockForKoba = action.payload.plsPerBlock
          state.status = 'idle'
        }
      })
      .addCase(fetchUserDataAsync.rejected, (state) => {
        state.status = 'failed'
      })
  },
})

export const { updateApprovalStatus, updateRewardTokenBalace, updateNFTUserDataWithEmpty } = userSlice.actions

export default userSlice.reducer
