import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state/hooks'
import { useFastFresh } from 'hooks/useRefresh'
import { fetchUserDataAsync, updateNFTUserDataWithEmpty, updateApprovalStatus, NFTData, updateRewardTokenBalace } from '.'
import { fetchAnonBalance, fetchApproveState } from './fetchNFTUser'

export const useNFTUserData = (_account: string | undefined, reload: boolean) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  
  useEffect(() => {
    if (_account) {
      dispatch(fetchUserDataAsync({ collection: 'koba', account: _account }))
    } else {
      dispatch(updateNFTUserDataWithEmpty())
    }
  }, [_account, fastRefresh, dispatch, reload])
}

// export const useAllNFTUserData = (_account: string | undefined, reload: boolean) => {
//   const dispatch = useAppDispatch()
//   // const fastRefresh = useFastFresh()
  
//   useEffect(() => {
//     if (_account) {
//       console.log("sniper: loading nft data...")
//       dispatch(fetchAllUserDataAsync({ account: _account }))
//     } else {
//       dispatch(updateNFTUserDataWithEmpty())
//     }
//   }, [_account, dispatch, reload])
// }

export const useApprovalStatus = (_account: string | undefined, reload: boolean) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  
  useEffect(() => {
    const fetchApprove = async (collection: string, _account: string) => {
      const status = await fetchApproveState(collection, _account)
      dispatch(updateApprovalStatus({collection: collection, approveStatus: status}))
    }
    if (_account) {
      fetchApprove("koba", _account)
    }
  }, [_account, reload, fastRefresh, dispatch])
}

export const useGetRewardTokenBalance = (_account: string | undefined, reload: boolean) => {
  const dispatch = useAppDispatch()
  const fastRefresh = useFastFresh()
  
  useEffect(() => {
    const fetchRewardTokenBalance = async (_account: string) => {
      const balance = await fetchAnonBalance(_account)
      dispatch(updateRewardTokenBalace({balance: balance}))
    }
    if (_account) {
      fetchRewardTokenBalance(_account)
    }
  }, [_account, reload, fastRefresh, dispatch])
}

export const useNFT = (collection: string) => {
  const data = useSelector((state: any) => state.user)
  let nfts: NFTData[] = []
  if (collection === "koba") {
    nfts = data.nfts
  }
  if(nfts)
    return nfts.slice().sort((prev: any, next: any) => Number(prev.id) - Number(next.id))
  else 
    return []
}

export const useStakedNFT = (collection: string) => {
  const data = useSelector((state: any) => state.user)
  let nfts: NFTData[] = []
  if (collection === "koba") {
    nfts = data.stakedNfts
  }
  if(nfts)
    return nfts.slice().sort((prev: any, next: any) => Number(prev.id) - Number(next.id))
  else 
    return []
}

export const useNFTApprovalStatus = (collection: string) => {
  const data = useSelector((state: any) => state.user)
  let approve: boolean = false
  if (collection === "koba") {
    approve = data.approveFornfts
  }
  return approve
}

export const useRewardTokenBalance = () => {
  return useSelector((state: any) => state.user.rewardTokenBalance)
}

export const useFamousNft = () => {
  return useSelector((state: any) => state.user.famous)
}

export const usePLSPerBlock = (collection: string) => {
  const data = useSelector((state: any) => state.user)
  let pending = 0
  if (collection === "koba") {
    pending = data.plsPerBlockForKoba
  }
  return pending
}
