const contracts = {
  koba: { // NFT
    5: '0x2626C0F54109ac02b827a4DdD9017039787Bd031',
    4: '0x5CF4890E08f50Dbc80A8A67FadbD5517922B2C37',
    1: '0x86d9b83e5fd24c36214dd84ea788a4db06ff6b22',
    369: '0x618c0e2c40487C86c313eF88217e86829c3355eE',
  },
  staking: { // NFT Staking
    5: '0xfD59c582549f858F22Eb4aA70C7c78046443Ab10',
    4: '0x36000E261Ad3fD443bc557020130FE09f905d9cb',
    1: '0x11faf8f647602f923672e2455322107a27acf9c1',
    369: '0xFF294917c1393F8edF2AB849d568655ecF9949Be',
  },
  reward: { // Reward Token
    5: '0x5695500096ea0bc119Ec0e5A37e99724C61b67E7',
    4: '0x39f2dB46AF00cdd849fd0014e20fa562669A9EcD',
    1: '0x8bCec35021d4b74D5dc506E76C83a9595a1D0414',
    369: '0x618c0e2c40487C86c313eF88217e86829c3355eE',
  },
  subReward: { // Reward Token
    5: '0x5CF4890E08f50Dbc80A8A67FadbD5517922B2C37',
    4: '0x39f2dB46AF00cdd849fd0014e20fa562669A9EcD',
    1: '0xd81ac057df314c44c6a2c16fa844c097b756bc67',
    369: '0x618c0e2c40487C86c313eF88217e86829c3355eE',
  },
  multicall: {
    5: '0x5CF4890E08f50Dbc80A8A67FadbD5517922B2C37',
    4: '0xa63B2b296A3c42C7EbFDC64a9B05f20A7361D5dd',
    1: '0x94f05e6869f26FC202797a68642d7500A9551288',
    369: '0x618c0e2c40487C86c313eF88217e86829c3355eE',
  },
  voting: {
    5: '0x552ab3a362b2D7fC4255019967100E6E201134fa',
    4: '0xa63B2b296A3c42C7EbFDC64a9B05f20A7361D5dd',
    1: '0x94f05e6869f26FC202797a68642d7500A9551288',
    369: '0x618c0e2c40487C86c313eF88217e86829c3355eE',
  },
}

export default contracts