// import React from 'react'
// import Header from 'views/Header';

// const discord = () => {
//   window.open("https://discord.gg/byanon");
// }

// const twitter = () => {
//   window.open("https://twitter.com/A__________by_A");
// }

// const Home = () => {

//   return (
//     <div className="allWrap">
//         <div className="light">
//             <Header />
//             <div className="vidCont">
//                 <video className="videoBg" autoPlay loop muted playsInline>
//                     <source src="images/bg.mp4" type="video/mp4" />
//                     Sorry, your browser doesn't support videos.
//                 </video>
//             </div>
//             <div className="announcement">
//                 <p className="line-2 anim-typewriter2">ANNOUNCEMENTS</p>
//                 <p className="announcementP2">Welcome to PulsePixel Cartel, <br /> PulsePixel Cartel Minting is Live. Dont Miss your chance!</p>
//                 <p className="lineP"></p>
//                 <button className="futuristic-button" onClick={() => window.location.href = '/mint'}>MINT</button>
//             </div>
//         </div>

//     </div>
//   )
// }

// export default Home

import BackStoryImg from "../../assets/static/imgs/backstory.png";
import RoadmapImg from "../../assets/static/imgs/Roadmap-new.png";
import HeroArrow from "../../assets/static/imgs/Hero-arrow.png";
import HeroImg from "../../assets/static/imgs/hero-avatar.png";
import Header from "views/Header";
import Footer from "components/Footer";

const Home = () => {
  return (
    <div className="ns-main-sec">
      <div className="hero-bg">
        <Header />
        {/* Hero Section  */}
        <div className="ns-new-container">
          <div className="ns-hero-sec">
            <div className="ns-hero-left">
              <span>3333</span>
              <h1>UNIQUE CARTEL NFTS WAITING TO BE MINTED</h1>
            </div>
            <div className="ns-hero-right">
              <img src={HeroImg} alt="HeroImg" />
            </div>
          </div>
        </div>
        <div className="ns-liear-bg">
          <img src={HeroArrow} alt="HeroArrow" />
        </div>
        {/* End Hero Section  */}
      </div>
      <div className="ns-new-container">
        {/* Backstory Section  */}
        <section className="ns-backs-sec">
          {/* Right Side  */}
          <div className="ns-bs-right">
            <h3>BACKSTORY</h3>
            <p>
              In the digital realm, a unique phenomenon emerged—the PulsePixel Cartel. Inspired by the intrigue of mafia
              lore and pixel art, this clandestine network created an empire of 3,333 NFTs.In the year 20XX, the
              PulsePixel Cartel, led by the enigmatic Kingpin Pixelotti, rules the pixelated underworld. Each NFT within
              this collection represents a character, a piece of contraband, or a moment in the Cartel&apos;s shadowy
              history. The pixelated artworks unveil the Cartel&apos;s rise, from bootlegging to cyber heists, all
              depicted in intricate detail.In the PulsePixel Cartel, alliances shift, fortunes are made, and power is
              never secure. As an NFT holder, you gain entry to this secret society, with the chance to shape its
              destiny or unseat Kingpin Pixelotti.Welcome to a world where pixels possess hidden significance, and where
              the digital divide between law-abiding and lawless blurs within the blockchain&apos;s heart.
            </p>
            <button className="ns-header-btn-sec">EXPLORE NFT</button>
          </div>
          {/* Left Side  */}
          <div className="ns-bs-left">
            <img src={BackStoryImg} alt="BackStoryImg" />
          </div>
        </section>
        {/* End Backstory Section  */}

        {/* Roadmap Section  */}
        <section className="ns-roadmap-sec">
          {/* Roadmap Left */}
          <div className="ns-roadmap-left">
            <h3>ROADMAP</h3>
            <div className="ns-roadmap-list">
              {/* Roadmap List  */}
              <div>
                <span>1. Design Character</span>
              </div>
              <div>
                <span>2. Research traits to be included</span>
              </div>
              <div>
                <span>3. Generate Collection</span>
              </div>
              <div className="ns-road-list-flex">
                <div>
                  <span>4. Passive income model planning</span>
                </div>
                <div>
                  <span>5. Contract creations</span>
                </div>
              </div>
              <div className="ns-road-list-flex">
                <div>
                  <span>6. Mint date set</span>
                </div>
                <div>
                  <span>7. Future external revenue source planning</span>
                </div>
              </div>
            </div>
          </div>
          <div className="ns-roadmap-right">
            <img src={RoadmapImg} alt="RoadmapImg" />
          </div>
          {/* Roadmap Right  */}
        </section>
        {/* End Roadmap Section  */}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
