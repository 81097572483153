import { getStakingContract } from '../contractHelpers'
import { getKobaAddress } from 'utils/addressHelpers'

export const callStakeNFTs = async (provider: any, address: string | undefined, collection: string, ids: number[]) => {
  const stakingContract = getStakingContract(provider)
  
  try {
    await stakingContract.methods.stake(ids).send({from: address});
    return true
  } catch (error) {
    return false    
  }
  
}

export const callUnstakeNFTs = async (provider: any, address: string | undefined, collection: string, ids: number[]) => {
  const stakingContract = getStakingContract(provider)
  
  try {
    await stakingContract.methods
        .unStake(ids)
        .send({ from: address })
    return true
  } catch (error) {
    return false
  }
}

export const callClaim = async (provider: any, address: string | undefined, collection: string, ids: number[]) => {
  const stakingContract = getStakingContract(provider)
  
  let collectionAddress
  if (collection === "koba") collectionAddress = getKobaAddress()

  try {
    await stakingContract.methods
        .claimRewards(ids)
        .send({ from: address });
    return true
  } catch (error) {
    return false
  }
}