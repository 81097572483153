// import React from 'react'

// import logo from '../../assets/logo.png';
// import Header from 'views/Header'

// const About = () => {

//   return (
//     <div className="stakeMainPage">
//       <div className="aboutPageMain">
//           <Header />

//           <div className="aboutPageMain2">
//               <p><div className="glitch" data-text="Hello Anon,">Hello Anon,</div></p>
//           </div>

//           <div className="aboutP">
//               <p>I am so pleased to have you in attendance. Your visual identity within the ecosystem has now arrived. Our mission remains the same, who I am is not important, the art is. anon is an artist, anon is an ecosystem, anon is a person, anon is a team, anon is a platform. But again, who I am is not important, the art is.</p>
//               <p>This is our belief and this will always be our focus. Coming soon to the anon HQ, holders of <a href="https://opensea.io/collection/abstraction-by-anon" target='_blank' rel="noreferrer">Abstraction</a>, <a href="https://opensea.io/collection/who-i-am-is-not-important-the-art-is-by-anon" target='_blank' rel="noreferrer">WIAINITAI</a>, and ANON by anon can stake their NFT to earn our in-house currency <a >$PLS</a>. For now, <a >$PLS</a> can only be used within our marketplace where we will focus to make digital and physical goods available for redemption only using <a >$PLS</a> – all with a focus on art and web3. Art is why we are here and we will continue to curate the best experiences, performances, and memories for everyone in attendance.</p>
//               <p>The anon DAO. Enabling holders the ability to govern the project through a decentralized in-house platform. Within our DAO, <a href="https://opensea.io/collection/abstraction-by-anon" target='_blank' rel="noreferrer">Abstraction</a> holders will have the highest governance input followed by <a href="https://opensea.io/collection/who-i-am-is-not-important-the-art-is-by-anon" target='_blank' rel="noreferrer">WIAINITAI</a> and ANON by anon. Our mission is to create a true decentralized art based ecosystem governed by the holders.</p>

//               <p></p>
//               <div className="aboutDiv">
//                   <p>Abstraction = Earn 25 $PLS per day</p>
//                   <p>WIAINITAI = Earn 5 $PLS per day</p>
//                   <p>ANON by anon = Earn 1 $PLS per day</p>
//               </div>
//           </div>

//           <div className="cMain">
//               <p className="cLine"></p>
//               <img src={logo} alt="logo"/>
//               <div>© 2023 PulsePixel Cartel NFTs. All rights reserved.</div>
//               <p className="cLine"></p>
//           </div>
//       </div>

//   </div>
//   )
// }

// export default About

import ImgOne from "../../assets/static/imgs/team-member-1.png";
import ImgTwo from "../../assets/static/imgs/team-member-2.png";
import ImgThree from "../../assets/static/imgs/team-member-3.png";
import ImgArrow from "../../assets/static/imgs/arrow-down.png";
import { useState } from "react";
import Header from "views/Header";
import Footer from "components/Footer";

const About = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (id: any) => {
    setActiveIndex((prevIndex) => (prevIndex === id ? null : id));
  };

  const faqs = [
    {
      id: 0,
      question: "When is mint?",
      answer:
        "Our minting date for the NFT project is set for 1st February. We're excited to share this milestone with our community and can't wait for you to join us in this journey!",
    },
    {
      id: 1,
      question: "What is total supply?",
      answer:
        'Our total supply consists of 3333 items.',
    },
    {
      id: 2,
      question: "What is mint price?",
      answer:
        "The public mint price is set at 100000 PLS.",
    },
    {
      id: 3,
      question: "Passive income?",
      answer:
        "Possess a stake in the minting fees through your NFT. Each NFT bestows upon you a share of the minting fees and royalties, enabling you to generate passive income.",
    },
    {
      id: 4,
      question: "Staking?",
      answer:
        "Secure rewards by staking your NFT. Rewards stem from 10% royalties and 10% of minting fees.",
    },
  ];

  return (
    <div className="ns-main-sec">
      <Header />
      <div className="ns-new-container">
        <div className="ns-team-wrapper">
          <h3>TEAM</h3>
          <div className="ns-flex-team">
            {/* Left Side  */}
            <div className="ns-team-left">
              {/* <h3>TEAM</h3> */}
              <div className="ns-member">
                <div className="ns-member-card">
                  <img src={ImgOne} alt="ImgOne" />
                  <div className="member-badge">
                    <h5>Founder</h5>
                  </div>
                </div>
              </div>
              <p>
                <span> NFTGoof - </span>
                As the founder of an NFT project, He leads the vision and execution, blending creativity and technology
                to redefine digital ownership experiences through unique and valuable non-fungible tokens.
              </p>
            </div>
            {/* Right Side  */}
            <div className="ns-team-right">
              <div>
                <div className="ns-member-2">
                  <div className="ns-member-card">
                    <img src={ImgTwo} alt="ImgTwo" />
                    <div className="member-badge">
                      <h5>Project Advisor</h5>
                    </div>
                  </div>
                </div>
                <p>
                  <span>Lorenzo Liuba - </span>
                  As a blockchain business manager, He oversees and streamline operations, fostering growth and
                  innovation within the blockchain industry.
                </p>
                <div className="ns-member-2">
                  <div className="ns-member-card">
                    <img src={ImgThree} alt="ImgThree" />
                    <div className="member-badge">
                      <h5>Developer</h5>
                    </div>
                  </div>
                </div>
                <p>
                  <span>Pastukhov Illia - </span>
                  As a blockchain developer, He leverages his expertise in programming and blockchain technologies to
                  create secure and innovative solutions for decentralized applications and smart contracts.
                </p>
              </div>
            </div>
          </div>
          {/* FAQs  */}

          <div className="ns-faq-container">
            <h5 className="ns-faq-heading">FAQs</h5>
            <div className="ns-faq-wrapper">
              {faqs.map((faq) => (
                <div key={faq.id} className="mb-4">
                  <div className="ns-faq-question" onClick={() => toggleAccordion(faq.id)}>
                    <div>
                      <h5 className="text-primaryblack font-cr text-xl">{faq.question}</h5>
                    </div>
                    <div className="text-[#858585]">
                      <span className="text-3xl">
                        {activeIndex === faq.id ? (
                          <img src={ImgArrow} alt="ImgArrow" style={{ transform: "rotate(180deg)" }} />
                        ) : (
                          <img src={ImgArrow} alt="ImgArrow" />
                        )}
                      </span>
                    </div>
                  </div>
                  {activeIndex === faq.id && (
                    <div className="px-4 pt-4 rounded mt-2 ns-faq-answer">
                      <p className="text-[#858585] font-cr text-lg">{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* End FAQs  */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
